import React, { useEffect, useState } from "react"
import { get } from 'lodash';
import TimelineNav from "../../../components/timelinenav/timelineNav"
import CustomButton from "../../../components/Button/button"
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import { TextField } from "@mui/material";
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import InputAdornment from '@mui/material/InputAdornment';
import { Col, Row } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import avatar from "src/assets/images/avatar.jpeg"
import addIconWhite from "src/assets/images/addiconwhite.svg"
import activateIcon from "src/assets/images/activateicon.svg"
import swapIcon from "src/assets/images/swapicon.svg"
import upIcon from "src/assets/images/upicon.svg"
import infoIcon from "src/assets/images/informationcircle.svg"
import dropdTokanIcon from "src/assets/images/dropdtokanicon.svg";
import plus from "src/assets/images/plus.svg";
import FriendsListModal from "src/pages/flics/components/friendsList/FriendsListModal";

import Balance from 'src/pages/mywallet/components/balance';

import { getWalletBalance } from "src/web3/getWalletBalance";


import "src/pages/flics/flics.css"

import { useAuth } from 'src/context/web3authProvider/AuthContext';
import useWeb3Auth from "src/web3/useWeb3Auth";

import { sendTokensPeerToPeer } from "src/web3/PeerToPeer";

import { useSpinner } from "../../../context/loaderContext/globalSpinnerContext";

import { GET_FRIEND_LIST } from "src/axios/GET_API";

function SendTokens({
  setSliderPage,
  amount
}) {
  // State management
  const [terms, setTerms] = useState(false);
  const [showFriendsListModal, setShowFriendsListModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [tokensAmount, setTokensAmount] = useState(amount ?? 1);
  const [walletBalance, setWalletBalance] = useState(0);
  const [friendsList, setFriendsList] = useState([]);
  const [tokenRecipient, setTokenRecipient] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);

  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [transactionError, setTransactionError] = useState('');

  const { initWallet } = useWeb3Auth();
  var { authProvider } = useAuth();
  const spinner = useSpinner();

  // Initial data loading
  useEffect(() => {
    async function fetchBalance() {
      try {
        spinner.setLoadingState(true);
        setError('');
        
        // Parallel API calls for better performance
        const [balanceResult, friendResponse] = await Promise.all([
          getWalletBalance(),
          GET_FRIEND_LIST()
        ]);

        setWalletBalance(balanceResult);
        setFriendsList(get(friendResponse, 'data.friend_list', []));
        setIsInitialLoadComplete(true);
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setError('Failed to load required data. Please try again.');
      } finally {
        setIsLoading(false);
        spinner.setLoadingState(false);
      }
    }
    fetchBalance();
  }, []);

  // Amount handling functions
  const handleAmountChange = (newValue) => {
    const validatedValue = Math.max(1, Math.min(newValue, Math.min(walletBalance, 100000)));
    setTokensAmount(validatedValue);
  };

  const handleInputChange = (event) => {
    const value = event.target.value === '' ? '' : Number(event.target.value);
    if (value === '' || (Number.isFinite(value))) {
      handleAmountChange(value);
    }
  };

  const handleSliderChange = (_, newValue) => {
    handleAmountChange(newValue);
  };

  // Validation functions
  const isFormValid = () => {
    return (
      isInitialLoadComplete &&
      tokenRecipient?.wallet_address &&
      tokensAmount >= 1 &&
      tokensAmount <= walletBalance &&
      terms
    );
  };

  // Transaction handling
  const handleSendTokens = async () => {
    try {
      spinner.setLoadingState(true);
      setError('');

      if (!authProvider) {
        const smartWallet = await initWallet();
        authProvider = smartWallet;
      }

      // Start transaction
      const txReceipt = await sendTokensPeerToPeer(
        authProvider, 
        tokenRecipient.wallet_address, 
        tokensAmount
      );

      if (txReceipt) {
        setError('');
        setShowConfirmationModal(false);
        
        // Show "Updating balance..." message
        setIsLoading(true);

        // Update balance after transaction
        const updatedBalance = await getWalletBalance();
        setWalletBalance(updatedBalance);

        // Show success modal
        setShowSuccessModal(true);
      } else {
        // If no transaction receipt, show failure modal
        setTransactionError('Transaction failed. No transaction receipt received.');
        setShowFailureModal(true);
      }
    } catch (error) {
      console.error('Transaction failed:', error);
      setTransactionError(error.message || 'Failed to send tokens. Please try again.');
      setShowFailureModal(true);
    } finally {
      setIsLoading(false);
      spinner.setLoadingState(false);
    }
  };

  // Success Modal Component
  const SuccessModal = () => (
    <Modal
      show={showSuccessModal}
      onHide={() => setShowSuccessModal(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Transaction Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="transaction-status-icon">
          <div className="status-icon success">
            <svg 
              className="success-icon" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
        </div>
        <h4 className="status-title success">Transaction Successful!</h4>
        <div className="transaction-details">
          <div className="detail-row">
            <span>Amount Sent:</span>
            <span>{tokensAmount} DRPD Tokens</span>
          </div>
          <div className="detail-row">
            <span>Recipient:</span>
            <span>{tokenRecipient?.nick_name}</span>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton
          variant="primary"
          onClick={() => setShowSuccessModal(false)}
        >
          Done
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );


  // Failure Modal Component
  const FailureModal = () => (
    <Modal
      show={showFailureModal}
      onHide={() => setShowFailureModal(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Transaction Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="transaction-status-icon">
          <div className="status-icon failure">
            <svg 
              className="failure-icon" 
              viewBox="0 0 24 24" 
              fill="none" 
              stroke="currentColor" 
              strokeWidth="2"
            >
              <path 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
        </div>
        <h4 className="status-title failure">Transaction Failed</h4>
        <div className="transaction-details">
          <div className="error-message">
            {transactionError}
          </div>
          <div className="help-text">
            Please try again or contact support if the problem persists.
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="button-wrap">
          <CustomButton
            variant="secondary"
            onClick={() => setShowFailureModal(false)}
          >
            Close
          </CustomButton>
          <CustomButton
            variant="primary"
            onClick={() => {
              setShowFailureModal(false);
              setShowConfirmationModal(true);
            }}
          >
            Try Again
          </CustomButton>
        </div>
      </Modal.Footer>
    </Modal>
  );

  // Confirmation Modal Component
  const ConfirmationModal = () => (
    <Modal
      show={showConfirmationModal}
      onHide={() => setShowConfirmationModal(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirm Transaction</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="confirmation-details">
          <h4>Transaction Details</h4>
          <div className="detail-row">
            <span>Amount:</span>
            <span>{tokensAmount} DRPD Tokens</span>
          </div>
          <div className="detail-row">
            <span>Recipient Name:</span>
            <span>{tokenRecipient?.nick_name}</span>
          </div>
          <div className="detail-row">
            <span>Recipient Wallet Address:</span>
            <span className="wallet-address">{tokenRecipient?.wallet_address}</span>
          </div>
          <div className="warning-text">
            This transaction cannot be reversed once confirmed.
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="button-wrap">
          <CustomButton
            variant="secondary"
            onClick={() => setShowConfirmationModal(false)}
          >
            Cancel
          </CustomButton>
          <CustomButton
            variant="primary"
            onClick={handleSendTokens}
          >
            Confirm Send
          </CustomButton>
        </div>
      </Modal.Footer>
    </Modal>
  );

  return (
    <div className='page-page-wrapper flics-page-container'>
      <div className="pnkbg" style={{width: "100%"}}>
        <TimelineNav />
      </div>

      <div className="inner-pages-container">
        {error && (
          <div className="error-message alert alert-danger">
            {error}
          </div>
        )}

        <div className="half-pnk">
          <div className="wallet-title-menu-wrap">
            <h1 className="page-title">My Wallet</h1>
          </div>
        </div>

        <div className="inner-pages-container-wrap">
          {/* Balance Section */}
          <div className="wallet-popup-conainer">
            <div className="add-drpd-btn-wrap">
              <span>Balance</span>
            </div>
            <div className="balance">
              <span><img src={dropdTokanIcon} /></span>
              
              {isLoading ? (
                <span>Updating Bal...</span>
              ) : (
                <span>{walletBalance}</span>
              )}
            </div>
          </div>

          {/* Friend Selection Section */}
          <div className="input-box input-field-select-friend">
            <span className="lbl">Send DRPD Tokens to</span>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Select Friend"
                style={{ 'borderRight': 'none' }}
                value={tokenRecipient?.nick_name}
                disabled={!!tokenRecipient?.nick_name}
                readOnly
              />
              <InputGroup.Text
                style={{ 
                  'backgroundColor': 'white',
                  cursor: isInitialLoadComplete ? 'pointer' : 'not-allowed',
                  opacity: isInitialLoadComplete ? 1 : 0.7
                }}
                onClick={() => isInitialLoadComplete && setShowFriendsListModal(true)}
              >
                <img id="cursor" src={plus} />
              </InputGroup.Text>
            </InputGroup>

            <div className="my-3">
              <p className="big-text">
                Send DRPD tokens directly to your friends. Once sent, the transfer will be processed on the blockchain and cannot be reversed.
              </p>
              <div className="big-text">
                <Form.Check
                  type={'checkbox'}
                  id="terms-checkbox"
                  disabled={!isInitialLoadComplete}
                  checked={terms}
                  label={`I understand this is an irreversible transaction and agree to the terms and privacy policy`}
                  onChange={(e) => setTerms(e.target.checked)}
                />
              </div>
            </div>
          </div>

          {/* Amount Selection Section */}
          <div style={{ padding: "0 20px" }}>
            <div style={{ 
              display: "flex", 
              justifyContent: "space-between",
              marginBottom: "8px"
            }}>
              <span style={{
                color: '#6A7587',
                fontSize: 10,
              }}>
                Min: 1
              </span>
              <span style={{
                color: '#6A7587',
                fontSize: 10,
              }}>
                Max: {Math.min(walletBalance, 100000)}
              </span>
            </div>
            <Box sx={{ width: "100%" }}>
              <Slider
                value={tokensAmount}
                aria-label="Default"
                valueLabelDisplay="auto"
                min={1}
                max={Math.min(walletBalance, 100000)}
                onChange={handleSliderChange}
                disabled={!isInitialLoadComplete}
              />
            </Box>
          </div>

          <div className="input-box input-field-amount">
            <div className="lbl">Enter DRPD Tokens amount you want to send</div>
            <div className="amount-input-field">
              <TextField
                id="outlined-start-adornment"
                variant="outlined"
                size='small'
                disabled={!isInitialLoadComplete}
                error={!!error || tokensAmount > walletBalance}
                helperText={error || (tokensAmount > walletBalance ? 'Amount exceeds wallet balance' : '')}
                sx={{
                  width: "100%",
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": {
                      border: "1px solid",
                      borderRadius: "12px",
                      borderColor: "#000000de"
                    }
                  },
                }}
                InputProps={{
                  endAdornment: <InputAdornment position="end"><img src={dropdTokanIcon} /></InputAdornment>,
                }}
                value={tokensAmount}
                onChange={handleInputChange}
              />
            </div>
          </div>

          {/* Send Button */}
          <div className="button-wrap">
            <CustomButton
              disabled={!isFormValid()}
              onClick={() => setShowConfirmationModal(true)}
            >
              Send Tokens
            </CustomButton>
          </div>
        </div>
      </div>

      {/* Modals */}
      {showFriendsListModal && (
        <FriendsListModal
          showFriendsListModal={showFriendsListModal}
          setShowFriendsListModal={setShowFriendsListModal}
          selectedCard={tokenRecipient}
          setSelectedCard={setTokenRecipient}
          friendsList={friendsList}
        />
      )}

      <ConfirmationModal />

      <SuccessModal />

      <FailureModal />
    </div>
  );
}

export default SendTokens;