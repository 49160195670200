import {
  createSmartAccountClient,
  BiconomySmartAccountV2,
  PaymasterMode,
} from "@biconomy/account";
import { ethers } from "ethers";
import { contractABI } from "../contract/contractABI";
import { flicsABI } from "../contract/flicsABI";
import { chains } from "../chain";
import { getWalletBalance } from "./getWalletBalance";
import { toast } from "react-toastify";

export const sendTokensToActivateFlics = async (authProvider, flicsAmount) => {
    toast.info("Sending ROCCA Activate Transaction. Please Wait.")
    const walletBalance = await getWalletBalance();
    if (walletBalance < flicsAmount) {
      toast.warn('Insufficient Wallet Balance');
      return;
    }

    const contractAddress = chains.dropdContractAdd;
    const flicsAddress = chains.flicsPoolContractAdd;
    const provider = new ethers.providers.JsonRpcProvider(
        chains.providerUrl
    );
    const contractInstance = new ethers.Contract(
        contractAddress,
        contractABI,
        provider
    );
    const flicsInstance = new ethers.Contract(
        flicsAddress,
        flicsABI,
        provider
    )
    const walletAddress = localStorage.getItem("wallet_address");
    const approvedAmount = ethers.utils.parseEther(flicsAmount);

  try {
    const minTx1 = await contractInstance.populateTransaction.approve(flicsAddress, approvedAmount);
  
    const tx1 = {
      to: contractAddress,
      data: minTx1.data
    };
    const minTx2 = await flicsInstance.populateTransaction.activateFLICS(walletAddress, approvedAmount);
  
    const tx2 = {
      to: flicsAddress,
      data: minTx2.data
    };

    //@ts-ignore
    let i = 0;
    const userOpResponse = await authProvider?.sendTransaction([tx1,tx2], {
      paymasterServiceData: { mode: PaymasterMode.SPONSORED },
      nonceOptions: {nonceKey: i++ }
    });
    //@ts-ignore
    const { transactionHash } = await userOpResponse.waitForTxHash();

    if (transactionHash) {
      return true;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};
