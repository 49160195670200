import React, { useState } from "react";
import PropTypes from 'prop-types';

import activateIcon from "src/assets/images/activateicon.svg";
import swapIcon from "src/assets/images/swapicon.svg";
import upIcon from "src/assets/images/upicon.svg";
import infoIcon from "src/assets/images/informationcircle.svg";

import { sendTokensToRefundFlics } from "../../../web3/refundFlics";
import { useAuth } from 'src/context/web3authProvider/AuthContext';
import useWeb3Auth from "src/web3/useWeb3Auth";

import { useSpinner } from "../../../context/loaderContext/globalSpinnerContext";

import { useNavigate } from "react-router-dom";

const FlicsButtons = ({
    activateFlics,
    flicsAmount,
    shareFlics,
    flicsHistory,
    flicsAmountHours,
    onActiveFlics
}) => {
    const history = useNavigate();

    var { authProvider } = useAuth();
    const { initWallet } = useWeb3Auth();
    const spinner = useSpinner();

    const [popupVisible, setPopupVisible] = useState(false);
    const togglePopup = () => {
        setPopupVisible(!popupVisible);
    };
    const closePopup = () => {
        setPopupVisible(false);
    };

    const initiator = localStorage.getItem("wallet_address");


    // Flics Recall Functionality
    const handleFlicsRecallButtonClick = async () => {
        if (flicsAmount === 0) {
          alert("It seems that no ROCCA have been activated yet. Please activate ROCCA before attempting to recall them.");
        } else {
          spinner.setLoadingState(true);
          if (!authProvider) {
            const smartWallet = await initWallet();
            authProvider = smartWallet;
          }
          const txRecipt = await sendTokensToRefundFlics(authProvider, initiator);

          if(txRecipt){
            alert("We've accepted your ROCCA recall request. It will be updated in your wallet shortly.");
          }
          spinner.setLoadingState(false);
        }
    };


    const isPositiveNumber = (value) => {
        return typeof value === 'number' && value > 0;
    };

    // Determine if the button should be disabled
    const isDisabled = flicsAmount === "Loading..." || !isPositiveNumber(flicsAmount);

    // In case flicsAmount is not a positive number, apply a default style
    const spanStyle = flicsAmount === "Loading..." || flicsAmount <= 0 ? { color: '#C4C4C4' } : {};


    return (
        <div className="flics-tabs-container">
            <div className="flics-options">

                <button 
                    disabled={!!flicsAmount || onActiveFlics} 
                    onClick={!onActiveFlics ? activateFlics : undefined} 
                >
                    <span style={!!flicsAmount || onActiveFlics ? { color: '#C4C4C4' } : {}}>
                        Activate ROCCA
                    </span>
                    <span className="tab-icon">
                        <img src={activateIcon} />
                    </span>
                </button>


                <button disabled={isDisabled} onClick={shareFlics}>
                    <span style={spanStyle}>Share ROCCA</span>
                    <span className="tab-icon"><img src={swapIcon} alt="Swap Icon" /></span>
                </button>

                <button onClick={flicsHistory}>
                    <span>ROCCA History</span> 
                    <span className="tab-icon"><img src={upIcon} /></span>
                </button>

                <button onClick={togglePopup}><span>Know your ROCCA</span> <span className="tab-icon"><img src={infoIcon} /></span></button>



                {(flicsAmount === 0) ? (
                  <button disabled>
                    <span style={{ color: '#C4C4C4' }}>Recall ROCCA - No ROCCA activated yet</span>
                    <span className="tab-icon"><img src={infoIcon} alt="info" /></span>
                  </button>
                ) : (
                  (flicsAmountHours !== undefined && flicsAmountHours > 72) ? (
                    <button onClick={handleFlicsRecallButtonClick}>
                      <span>Recall ROCCA</span> 
                      <span className="tab-icon"><img src={infoIcon} alt="info" /></span>
                    </button>
                  ) : (
                    <button disabled>
                      {flicsAmountHours !== undefined ? (
                        <span style={{ color: '#C4C4C4' }}>
                          Recall ROCCA, Available in {Math.ceil(72 - flicsAmountHours)}h
                        </span>
                      ) : (
                        <span style={{ color: '#C4C4C4' }}>Fetching ROCCA data...</span>
                      )}
                      <span className="tab-icon"><img src={infoIcon} alt="info" /></span>
                    </button>
                  )
                )}

            </div>

            {popupVisible && (
                <div className="popup">
                    <p><strong>Know your ROCCA</strong></p>
                    <ul>
                        <li>ROCCA is a courtship product.</li>
                        <li>ROCCA is a floating commitment bond that lapses after 180 days.</li>
                        <li>One can deposit 100 (min) to 100,000 (max) tokens in this bond.</li>
                        <li>ROCCA buyer gets “Jump-the-Queue” advantage in the list of matches.</li>
                        <li>Anyone can initiate ROCCA and can share it with any friend.</li>
                        <li>Higher the ROCCA amount, better the matchmaking chance.</li>
                        <li>Tokens are automatically transferred to recipient of ROCCA after 6 months.</li>
                        <li>ROCCA feature has a platform fee of 5% of the staked tokens.</li>
                        <li>Neither party gets tokens if ROCCA is deleted in first 90 days.</li>
                        <li>Both parties split tokens 50:50 if ROCCA is deleted after 90 days but before 180 days.</li>
                    </ul>

                    <button className="close-btn" onClick={closePopup}>Back!</button>
                    
                </div>
            )}

        </div>
    );
};

FlicsButtons.prototype = {
    activateFlics: PropTypes.func,
    amount: PropTypes.string,
    shareFlics: PropTypes.func,
    flicsHistory: PropTypes.func,
}

export default FlicsButtons;