import React, { useState, useEffect } from "react";
import CustomButton from "../../../components/Button/button";
import { Grid, InputBase, TextField, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import TimelineNav from "../../../components/timelinenav/timelineNav";
import { useTranslation } from "react-i18next";
import search from "src/assets/images/search.svg";
import "./createclubdetails.css";
import interest from "../../../utils/interests/interest";
import ClubNamePopup from "./createclubnamepopup";
import { Country, State, City } from "country-state-city";
import { useSpinner } from "../../../context/loaderContext/globalSpinnerContext";

function CreateClubDetails({ 
  setSliderPage, 
  setClubName, 
  setDescription, 
  clubName, 
  description, 
  setInterests, 
  interests,
  setCountry, 
  country, 
  setState, 
  state, 
  setCity, 
  city,
  setJoiningFee,
  joiningFee,
  walletBalance
}) {
  const { t, i18n } = useTranslation();
  const spinner = useSpinner();
  
  const [detailsError, setDetailsError] = useState();
  const [popupOpen, setPopupOpen] = useState(false);
  const [feeInfoPopupOpen, setFeeInfoPopupOpen] = useState(true);

  const [statesList, setStatesList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const CLUB_CREATION_FEE = 50; 
  
  
  // const CLUB_CREATION_FEE = 50; 

  // useEffect(() => {
  //   const fetchWalletBalance = async () => {
  //     try {
  //       spinner.setLoadingState(true);
  //       if (walletBalance < CLUB_CREATION_FEE) {
  //         setWalletBalancePopupOpen(true);
  //       }
  //     } catch (error) {
  //       console.error("Failed to fetch wallet balance", error);
  //       setDetailsError("Unable to fetch wallet balance");
  //     } finally {
  //       spinner.setLoadingState(false);
  //     }
  //   };
  //   fetchWalletBalance();
  // }, []);

  const allowedCountries = [
    "Bangladesh", "Cambodia", "India", "Indonesia", "Iran", 
    "Laos", "Malaysia", "Myanmar", "Pakistan", "Philippines", 
    "Russia", "Singapore", "Thailand", "United Arab Emirates", "Vietnam"
  ];

  const filteredCountries = Country.getAllCountries()
    .filter(country => allowedCountries.includes(country.name))
    .sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    // Find the country with the full name
    const selectedCountry = Country.getAllCountries().find(c => c.name === country);
    
    if (selectedCountry) {
      // Get states for the selected country using its ISO code
      const statesOfCountry = State.getStatesOfCountry(selectedCountry.isoCode);
      setStatesList(statesOfCountry);
    } else {
      setStatesList([]);
    }
  }, [country]);

  useEffect(() => {
    if (country && state) {
      // Find the country and state
      const selectedCountry = Country.getAllCountries().find(c => c.name === country);
      const selectedState = State.getStatesOfCountry(selectedCountry.isoCode)
        .find(s => s.name === state);

      if (selectedCountry && selectedState) {
        // Get cities of the state
        const citiesOfState = City.getCitiesOfState(selectedCountry.isoCode, selectedState.isoCode);
        setCityList(citiesOfState);
      } else {
        setCityList([]);
      }
    }
  }, [state, country]);

  const handleClubPhoto = () => {
    // Validate all required fields
    if (!clubName) {
      setDetailsError("Please enter club name");
      return;
    }
    if (!description) {
      setDetailsError("Please enter club description");
      return;
    }
    if (!country) {
      setDetailsError("Please select a country");
      return;
    }
    if (!state) {
      setDetailsError("Please select a state/province");
      return;
    }
    if (!city) {
      setDetailsError("Please select a city");
      return;
    }
    if (!interests) {
      setDetailsError("Please select a club category");
      return;
    }
    if (!joiningFee) {
      setDetailsError("Please enter a joining fee");
      return;
    }

    // If all validations pass
    setDetailsError("");
    setSliderPage("photo");
  };

  const handleOpenPopup = () => {
    setPopupOpen(true);
  };

  const handleClosePopup = () => {
    setPopupOpen(false);
  };

  const handleSelectOption = (option) => {
    setClubName(option);
    handleClosePopup();
  };

  const handleCloseFeeInfoPopup = () => {
    setFeeInfoPopupOpen(false);
  };


  return (
    <div className="page-page-wrapper">
      <Dialog
        open={feeInfoPopupOpen}
        onClose={handleCloseFeeInfoPopup}
      >
        <DialogTitle>Club Creation Fee</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To create a club, a fee of {CLUB_CREATION_FEE} DRPD will be deducted from your wallet.
            
            Please ensure you have sufficient balance before proceeding with club creation.
            
            Current Wallet Balance: {walletBalance} DRPD
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFeeInfoPopup} color="primary">
            I Understand
          </Button>
        </DialogActions>
      </Dialog>

      <TimelineNav />
      <div className="inner-pages-container">
        <div className="inner-pages-container-wrap">
          <h1 className="page-title">Create a Club</h1>
          <div className="create-club-progress-wrap">
            <div className="active"></div>
            <div className="inactive"></div>
          </div>
          <div className="create-club-container">
            <div className="create-club-form">
              <div className="c-field">
                <div className='input-support-text' style={{ fontSize: "10px", fontWeight: "400" }}>Enter the club name or choose one from the list</div>
                <TextField
                  style={{width: "100%"}}
                  id="outlined-basic"
                  variant="outlined"
                  size="medium"
                  value={clubName}
                  onClick={handleOpenPopup}
                  placeholder="Name of the club"
                  onChange={(e) => setClubName(e.target.value)}
                  sx={{
                    width: "340px",
                    "& .MuiOutlinedInput-root": {
                      "& > fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </div>

              <div className='input-support-text'>Select Your Country</div>
              <select 
                onChange={(e) => { 
                  setCountry(e.target.value);
                  // Reset state and city when country changes
                  setState('');
                  setCity('');
                }} 
                className='marital-status' 
                value={country || ''}
                id="outlined-basic"
              >
                <option value="">Select Your Country</option>
                {filteredCountries.map((item, index) => (
                  <option key={index} value={item.name}>{item.name}</option>
                ))}
              </select>

              <div className='input-support-text'>Choose Your State/Province</div>
              <select 
                onChange={(e) => { 
                  setState(e.target.value);
                  // Reset city when state changes
                  setCity('');
                }} 
                className='marital-status' 
                value={state || ''}
                id="outlined-basic"
                disabled={!country}
              >
                <option value="">Choose Your State/Province</option>
                {statesList?.map((item, index) => (
                  <option key={index} value={item.name}>{item.name}</option>
                ))}
              </select>

              <div className='input-support-text'>Select Your City</div>
              <select 
                onChange={(e) => { setCity(e.target.value) }} 
                className='marital-status' 
                value={city || ''}
                id="outlined-basic"
                disabled={!state}
              >
                <option value="">Select City</option>
                {cityList?.map((item, index) => (
                  <option key={index} value={item.name}>{item.name}</option>
                ))}
              </select>

              <div className="desc-field">
                <div className='input-support-text' style={{ fontSize: "10px", fontWeight: "400" }}>Describe Your Club</div>
                <InputBase
                  style={{width: "100%"}}
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Briefly explain your club's purpose, activities, and goals"
                  multiline
                  rows={4}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  sx={{
                    width: "340px",
                    "& .MuiOutlinedInput-root": {
                      "& > fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </div>

              <div className="c-field">
                <div className='input-support-text' style={{ fontSize: "10px", fontWeight: "400" }}>
                  Select Club Category
                </div>

                <select
                  style={{ width: "340px", border: "0.5px solid grey", padding: "10px 10px" }}
                  onChange={(e) => setInterests(e.target.value)}
                  className="marital-status"
                  name="interests"
                  id="outlined-basic"
                  value={interests}
                >
                  <option value="">Select Category</option>
                  {interest.map((item, index) => (
                    <option key={index} value={item.text} style={{ marginBottom: "5px", fontSize: "14px" }}>
                      {item.text}
                    </option>
                  ))}
                </select>
              </div>

              <div className="c-field">
                <div className='input-support-text' style={{ fontSize: "10px", fontWeight: "400" }}>Club Joining Fee (DRPD)</div>
                <TextField
                  style={{width: "100%"}}
                  id="outlined-basic"
                  variant="outlined"
                  size="medium"
                  placeholder="Enter Joining Fee"
                  type="number"
                  value={joiningFee}
                  onChange={(e) => setJoiningFee(e.target.value)}
                  sx={{
                    width: "340px",
                    "& .MuiOutlinedInput-root": {
                      "& > fieldset": {
                        border: "none",
                      },
                    },
                  }}
                />
              </div>
              <div
                style={{ fontSize: "14px", position: "relative", bottom: "30px", left: "15px" }}
                className="error"
              >
                {detailsError}
              </div>
              <div className="button-wrap">
                <CustomButton onClick={handleClubPhoto}>{t("proceed.1")}</CustomButton>
              </div>
            </div>
          </div>
        </div>
      </div>

      
      
      <ClubNamePopup 
        open={popupOpen} 
        onClose={handleClosePopup} 
        onSelectOption={handleSelectOption} 
      />
    </div>
  );
}

export default CreateClubDetails;