import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';

import dropdTokanIcon from "src/assets/images/dropdtokanicon.svg";
import { getBalance } from "src/axios/POST_API";

import Balance from 'src/pages/mywallet/components/balance';

const WalletBalance = ({}) => {
    
    return (
        <div className="half-pnk">
            <h1 className="page-title">ROCCA</h1>
            <div className="flics-wallet-balance">Wallet Balance
                <span>
                    <img src={dropdTokanIcon} /><Balance />
                </span>
            </div>
        </div>
    );
};

WalletBalance.propTypes = {
    // Define prop types if needed
};

export default WalletBalance;