import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import TimelineNav from "../../components/timelinenav/timelineNav";
import plusIcon from "src/assets/images/plus.svg";
import { useNavigate } from "react-router-dom";
import "./clubs.css";
import FloatingToolbar from "../../components/FloatingToolbar";
import { VIEW_ALL_CLUBS, VIEW_MY_CLUBS, VIEW_JOINED_CLUBS } from "../../axios/GET_API";
import ClubLoader from "../../components/SkeletonLoader/Clubskeleton/Clubskeleton";
import InfiniteScroll from "react-infinite-scroll-component";

const { REACT_APP_CDN_HOST } = process.env;

function Clubs() {
  const history = useNavigate();

  // State for active tab
  const [activeTab, setActiveTab] = useState("all");

  // State for club data
  const [allClubsData, setAllClubsData] = useState([]);
  const [myClubData, setMyClubData] = useState([]);
  const [joinedClubData, setJoinedClubData] = useState([]);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  // Loading states
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState("");

  // Fetch Data Functions
  const fetchClubsData = async (page = 1, tab = "all") => {
    setLoading(true);
    setError("");
    try {
      let response;
      switch (tab) {
        case "my":
          response = await VIEW_MY_CLUBS(page);
          setMyClubData((prev) => (page === 1 ? response.data.club_list : [...prev, ...response.data.club_list]));
          break;
        case "joined":
          response = await VIEW_JOINED_CLUBS(page);
          setJoinedClubData((prev) => (page === 1 ? response.data.club_list : [...prev, ...response.data.club_list]));
          break;
        default:
          response = await VIEW_ALL_CLUBS(page);
          setAllClubsData((prev) => (page === 1 ? response.data.club_list : [...prev, ...response.data.club_list]));
          break;
      }
      setTotalPages(response.data.total_page_count);
      setHasMore(page < response.data.total_page_count);
    } catch (err) {
      setError(err.response?.data?.message || "Error fetching clubs");
    } finally {
      setLoading(false);
    }
  };

  // Handle Tab Change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1); // Reset to the first page
    setTotalPages(0); // Reset total pages
    setHasMore(true); // Reset "hasMore" state
    if (tab === "my") fetchClubsData(1, "my");
    else if (tab === "joined") fetchClubsData(1, "joined");
    else fetchClubsData(1, "all");
  };

  // Infinite Scroll Data Fetch
  const fetchMoreData = () => {
    if (currentPage < totalPages) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      fetchClubsData(nextPage, activeTab);
    } else {
      setHasMore(false);
    }
  };

  // Initial Data Fetch
  useEffect(() => {
    fetchClubsData(1, "all");
  }, []);

  // Render Club List
  const renderClubList = (data) => {
    return (
      <div className="clubs-container">
        {data.map((item) => {
          const profileImage = item?.profile_image ? `${REACT_APP_CDN_HOST}${item.profile_image.replace("//", "/")}` : null;
          const coverImage = item?.cover_image ? `${REACT_APP_CDN_HOST}${item.cover_image.replace("//", "/")}` : null;

          return (
            <div key={item.club_id} className="club-col" onClick={() => history(`/club/${item.club_id}`)}>
              <div className="round-icon">{profileImage && <img src={profileImage} alt={item.club_name} />}</div>
              <h3>{item.club_name}</h3>
              <div>
                <div className="page-likes">Likes <span className="likesCount">0</span></div>
                <div className="club-feature-img">
                  {coverImage && <img src={coverImage} alt={`${item.club_name} cover`} />}
                  <button className="like-page-button">
                    {item.is_member ? "View Club" : "View Details"}
                  </button>
                </div>
              </div>
            </div>
          );
        })}
        {error && <div className="error">{error}</div>}
      </div>
    );
  };

  // Get Data Based on Active Tab
  const getCurrentTabData = () => {
    switch (activeTab) {
      case "my":
        return myClubData;
      case "joined":
        return joinedClubData;
      default:
        return allClubsData;
    }
  };

  return (
    <div className="club-page-wrapper">
      <div style={{ width: "100%" }}>
        <TimelineNav />
        <div>
          <div className="inner-pages-container">
            <div className="inner-pages-container-wrap">
              <div className="club-title-create-tab-wrap">
                <h1 className="page-title">Clubs</h1>
                <button onClick={() => history("/createclub")}>
                  <span className="plus-icon"><img src={plusIcon} alt="Create Club" /></span>
                  Create Club
                </button>
              </div>
              <div className="club-tabs-wrap">
                <button className={activeTab === "all" ? "active" : ""} onClick={() => handleTabChange("all")}>
                  All Clubs
                </button>
                <button className={activeTab === "my" ? "active" : ""} onClick={() => handleTabChange("my")}>
                  My Clubs
                </button>
                <button className={activeTab === "joined" ? "active" : ""} onClick={() => handleTabChange("joined")}>
                  Joined Clubs
                </button>
              </div>
              {/* Infinite Scroll */}
              <InfiniteScroll
                dataLength={getCurrentTabData().length}
                next={fetchMoreData}
                hasMore={hasMore}
                loader={loading && <ClubLoader />}
                endMessage={
                  !loading && getCurrentTabData().length === 0 && (
                    <p style={{ textAlign: "center" }}>No more clubs to show.</p>
                  )
                }
              >
                {renderClubList(getCurrentTabData())}
              </InfiniteScroll>
            </div>
          </div>
          <FloatingToolbar />
        </div>
      </div>
    </div>
  );
}

export default Clubs;