import React, { useCallback, useEffect, useState } from 'react';
import confetti from 'canvas-confetti';

const ConfettiCelebration = ({ 
  trigger, 
  onAnimationComplete,
  particleCount = 1000, 
  spread = 200,
  origin = { x: 0.5, y: 0.5 }
}) => {
  const [showMessage, setShowMessage] = useState(false);
  const [hasCelebrated, setHasCelebrated] = useState(false);

  // Helper function to generate random number in range
  const r = (mi, ma) => {
    return Math.floor(Math.random() * (ma - mi + 1) + mi);
  };

  const celebrate = useCallback(() => {
    // Prevent multiple celebrations
    if (hasCelebrated) return;

    // Multiple rounds with dynamic parameters
    const rounds = 3;
    for (let i = 0; i < rounds; i++) {
      setTimeout(() => {
        confetti({
          particleCount: r(122, 245),
          angle: r(60, 120),
          spread: r(45, 80),
          origin: {
            x: 0.5,
            y: 0.5
          },
          colors: ['#26ccff', '#a25afd', '#ff5e7e', '#88ff5a', '#fcff42'],
          scalar: 1.5,
          startVelocity: r(30, 60)
        });
      }, i * 300); // Delay between rounds
    }

    // Show congratulation message after confetti
    setTimeout(() => {
      setShowMessage(true);
      
      const messageTimer = setTimeout(() => {
        setShowMessage(false);
        
        // Call the optional post-animation callback
        if (onAnimationComplete) {
          onAnimationComplete();
        }
      }, 3000);

      // Cleanup function to prevent memory leaks
      return () => {
        clearTimeout(messageTimer);
      };
    }, 1000);

    // Mark as celebrated
    setHasCelebrated(true);
  }, [onAnimationComplete]);

  useEffect(() => {
    let shouldCelebrate = false;

    if (typeof trigger === 'function') {
      shouldCelebrate = trigger();
    } else {
      shouldCelebrate = trigger;
    }

    if (shouldCelebrate && !hasCelebrated) {
      celebrate();
    }
  }, [trigger, celebrate, hasCelebrated]);

  return showMessage ? (
    <div className="congratulations-overlay">
      <div className="congratulations-container">
        Congratulations!
      </div>
    </div>
  ) : null;
};

export default ConfettiCelebration;