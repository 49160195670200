import React, { useState, useRef, useEffect } from 'react';
import TimelineNav from "../../../components/timelinenav/timelineNav";
import { useTranslation } from "react-i18next";
import avatar from "src/assets/images/avatar.jpeg";
import addIconWhite from "src/assets/images/addiconwhite.svg";
import activateIcon from "src/assets/images/activateicon.svg";
import swapIcon from "src/assets/images/swapicon.svg";
import upIcon from "src/assets/images/upicon.svg";
import infoIcon from "src/assets/images/informationcircle.svg";
import dropdTokanIcon from "src/assets/images/dropdtokanicon.svg";
import dropdCircle from "src/assets/images/dropdcircle.svg";
import incomeGiver from "src/assets/images/incomegiver.svg";
import tickCircleIcon from "src/assets/images/tickcircle.svg";
import unlockIcon from "src/assets/images/unlockicon.svg";
import lockIcon from "src/assets/images/lockicon.svg";
import menukebab from "src/assets/images/menukebab.svg";
import { useWeb3React } from "@web3-react/core";
import { useNavigate } from "react-router-dom";
import "../mywallet.css";
import Web3 from "web3";
import { claimDRPD } from "../../../web3/claim";
import { getDRPDBalance } from "../../../web3/drpd";

// import { getBalance } from "src/axios/POST_API";
import { internationalNumberFormat } from "../../../utils/numberFormat";
import { useWallet } from "../../../context/wallet/WalletContext";
import { CLAIM_DRPD, GET_ACCOUNT } from "../../../axios/POST_API";
import { USER_VIEW_PROFILE } from "../../../axios/GET_API";
import { useSpinner } from "../../../context/loaderContext/globalSpinnerContext";

import Balance from 'src/pages/mywallet/components/balance';

function Wallet({ setSliderPage }) {
  const history = useNavigate();
  const spinner = useSpinner();
  const { active, account, activate, deactivate, connector, library } =
  useWeb3React();
  const wallet = useWallet();
  const [balance, setBalance] = useState(parseFloat(0));
  const [expected, setExpected] = useState(parseFloat(0));

  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);

  const toggleMenu = () => setIsOpen(!isOpen);

  const walletAddress = localStorage.getItem("wallet_address");

  const copyWalletAddress = () => {
    navigator.clipboard.writeText(walletAddress);
    alert('Wallet address copied to clipboard!');
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const changeToTransaction = () =>{
    history("/transactions");
  }

  const changeToSendTokens = () =>{
    history("/sendtokens");
  }

  return (
    <div className="page-page-wrapper flics-page-container">
      <div className="pnkbg">
        <TimelineNav />
      </div>

      <div className="inner-pages-container">
        <div className="half-pnk">
            <div className="wallet-title-menu-wrap">
              <h1 className="page-title">My Wallet</h1>
              <div className="wallet-menu-container" ref={menuRef}>
                <button className="wallet-menu" onClick={toggleMenu}>
                  <img src={menukebab} />
                </button>
                {isOpen && (
                  <div className="popup-menu">
                    <button className="menu-item" onClick={copyWalletAddress}>
                      Copy Polygon PoS Address <span className="icon">&#x2398;</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
        </div>

        <div className="inner-pages-container-wrap">
          <div className="wallet-popup-conainer">
            <div className="add-drpd-btn-wrap">
              <span>Balance</span>
            </div>

            <div className="balance">
              <span>
                <img src={dropdTokanIcon} />
              </span>
              <span>
                <Balance />
              </span>
            </div>
          </div>

          {/* <div className="drpd-reward-container">
                <span className="r-title">DRPD Reward</span>
                <div className="drpd-reward-wrap">
                  <div className="reward-claimed">
                    <span>50 DRPD</span>
                    <span className="r-c-icon"><img src={tickCircleIcon}/></span>
                  </div>
                  <div className="reward-unlocked">
                    <span>50 DRPD</span>
                    <span className="r-c-icon"><img src={unlockIcon} /></span>
                  </div>
                  <div className="reward-locked">
                    <span>50 DRPD</span>
                    <span className="r-c-icon"><img src={lockIcon} /></span>
                  </div>
                  <div className="reward-locked">
                    <span>50 DRPD</span>
                    <span className="r-c-icon"><img src={lockIcon} /></span>
                  </div>
                </div>
              </div> */}

          <div className="wallet-tabs-container">
            <button className="transaction" onClick={changeToTransaction} ><span>Transactions</span> <span className="tab-icon"><img src={swapIcon}/></span></button>
          </div>


          <div className="wallet-tabs-container">
            <button className="transaction" onClick={changeToSendTokens} ><span>Send DRPD Tokens</span> <span className="tab-icon"><img src={dropdTokanIcon}/></span></button>
          </div>

          {/*<div className="income-card-container">
            <div className="income-popup-conainer">
              <div className="add-drpd-btn-wrap">
                <span>My Expected Income</span>
                <span></span>
              </div>

              <div className="balance">
                <span>
                  <img src={dropdTokanIcon} />
                </span>
                <span>773</span>
              </div>

              <div
                style={{
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "#6A7587",
                  textAlign: "center",
                  marginBottom: "10px",
                }}
              >
                <span>this month</span>
              </div>

              <div className="income-formula">
                <span>Likes</span>
                <span className="txt-blue sign">+</span>
                <span>Time Spent</span>
                <span className="txt-blue sign">=</span>
                <span style={{ marginRight: "10px" }}>
                  <img src={dropdTokanIcon} />
                </span>
                <span className="txt-blue">773</span>
              </div>
            </div>

            <div className="wallet-monthly-table">
              <table>
                <tbody>
                  <tr style={{ backgroundColor: "#ffffff" }}>
                    <th colSpan="3">This month</th>
                    <th>Avg. Unit value</th>
                    <th>Total</th>
                  </tr>
                  <tr style={{ backgroundColor: "#FAF3FF" }}>
                    <td className="lbl">Likes</td>
                    <td className="val">256</td>
                    <td className="lbl">x</td>
                    <td className="val">0.5</td>
                    <td className="total">128</td>
                  </tr>
                  <tr style={{ backgroundColor: "#FAF3FF" }}>
                    <td className="lbl">Time Spent</td>
                    <td className="val">860 mins</td>
                    <td className="lbl">x</td>
                    <td className="val">0.75</td>
                    <td className="total">645</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-between",
                backgroundColor: "#ffffff",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <span
                style={{ fontSize: 14, lineHeight: "20px", color: "#46164D" }}
              >
                Expected Income
              </span>
              <span
                style={{
                  fontSize: 14,
                  lineHeight: "16px",
                  fontWeight: "600",
                  display: "flex",
                  flexDirction: "row",
                }}
              >
                <img
                  style={{
                    width: "12px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "10px",
                  }}
                  src={dropdTokanIcon}
                />
                <span style={{ marginTop: "auto", marginBottom: "auto" }}>
                  773
                </span>
              </span>
            </div>
            <div className="monthly-income-wrp">
              <span>
                <img src={incomeGiver} />
              </span>
              <span>
                <span className="lbl">Feb</span>
                <span>
                  <img src={dropdTokanIcon} />
                  356
                </span>
              </span>
              <span>
                <span className="lbl">Mar</span>
                <span>
                  <img src={dropdTokanIcon} />
                  432
                </span>
              </span>
              <span>
                <span className="lbl">Apr</span>
                <span>
                  <img src={dropdTokanIcon} />
                  863
                </span>
              </span>
            </div>
          </div>*/}
          
        </div>
      </div>
    </div>
  );
}

export default Wallet;
