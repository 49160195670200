import { ethers } from "ethers";
import { contractABI } from "../contract/contractABI";
import { flicsABI } from "../contract/flicsABI";
import { chains } from "../chain";
export const getFlicsAmount = async () => {
    const contractAddress = chains.dropdContractAdd;
    const flicsAddress = chains.flicsPoolContractAdd;
    const provider = new ethers.providers.JsonRpcProvider(
        chains.providerUrl
    );
    const contractInstance = new ethers.Contract(
        contractAddress,
        contractABI,
        provider
    );
    const flicsInstance = new ethers.Contract(
        flicsAddress,
        flicsABI,
        provider
    )
    const walletAddress = localStorage.getItem("wallet_address");
    try {
        const minTx = await flicsInstance.getActiveFLICSAmount(walletAddress);
        const activationTime = await flicsInstance.activationTime(walletAddress);
        
        const activeAmount = parseFloat(minTx)
        const smallAmount = (activeAmount / 1e18);

        const currentTime = Math.floor(Date.now() / 1000);
        const timeDifferenceInSeconds = currentTime - activationTime;
        const timeDifferenceInHours = timeDifferenceInSeconds / 3600;

        if (minTx) {
            return { smallAmount, timeDifferenceInHours };
        }
        //@ts-ignore
    } catch (error) {
        console.log(error);
        alert('Activate ROCCA Unsuccessful');
    }
};