import React, { useState } from "react";
import PropTypes from 'prop-types';
import { get } from 'lodash'
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import ConfettiCelebration from 'src/components//ConfettiCelebration/ConfettiCelebration';

import CustomButton from "src/components/Button/button";
import Popup from "src/components/Popup";
import dropdTokanIcon from "src/assets/images/dropdtokanicon.svg";
import tickCircleIcon from "src/assets/images/tickcircle.svg";
import connectionIcon from "src/assets/images/connectionicon.svg";
import watchIcon from "src/assets/images/watchicon.svg";
import infoIcon from "src/assets/images/informationcircle.svg";
import "./FlicsDetails.css";

import { useAuth } from 'src/context/web3authProvider/AuthContext';
import useWeb3Auth from "src/web3/useWeb3Auth";

import { sendTokensToAcceptFlics } from "src/web3/FlicsAccept";
import { sendTokensToRejectFlics } from "src/web3/FlicsReject";

import { useSpinner } from "../../../context/loaderContext/globalSpinnerContext";

const { REACT_APP_CDN_HOST } = process.env;

const FlicsRequestDetails = ({
    setSliderPage,
    amount,
    flicsRecipient = {},
    selfProfile,
    flics,
}) => {
    var { authProvider } = useAuth();
    const { initWallet } = useWeb3Auth();
    const history = useNavigate();
    const spinner = useSpinner();

    const [isCelebrationTriggered, setIsCelebrationTriggered] = useState(false);

    const { nick_name, profile_pics, flics_address } = flicsRecipient;
    const [shouldShowPopup, setShouldShowPopup] = useState(false);
    const selfPic = `${REACT_APP_CDN_HOST}` + get(selfProfile, 'profile_pics[0]', "").replace("//", "/");
    const friendPic = `${REACT_APP_CDN_HOST}` + get(profile_pics, '0', "").replace("//", "/");

    const handleAnimationComplete = () => {
        history("/flics");
    };
    return (
        <div className="inner-pages-container">
            <div className="half-pnk">
                {/*<div className="inner-pages-container-wrap">*/}
                    {/* <h1 className="page-title">SHARED/DEPLOYED FLICS</h1> */}
                    <h1 className="page-title">Shared ROCCA</h1>
                {/*</div>*/}
            </div>
            <div className="inner-pages-container-wrap">
                <div className="flics-popup-conainer">
                    <div className="row justify-content-center">
                        <div className="row flics-header mb-3">
                            <div className="col-md-5">
                                <img style={{ width: '100%', height: '100%' }} src={selfPic} className="profile-circle" />
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <span>You</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2 mb-2">
                                <img src={connectionIcon} />
                            </div>
                            <div className="col-md-5">
                                <img style={{ width: '100%', height: '100%' }} src={friendPic} className="profile-circle" />
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        <span> {nick_name} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Row>
                            <Col md={4}>
                                <div className="flics-detail-left mb-2">ROCCA ID:</div>
                            </Col>
                            <Col md={8}>
                                <div className="flics-detail-right mb-2"><b>{flics_address}</b></div>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={4}>
                                <div className="flics-detail-left mb-2">ROCCA Amount:</div>
                            </Col>
                            <Col md={8}>
                                <div className="flics-detail-right mb-2">
                                    <img src={dropdTokanIcon} />
                                    <b className="ml-2 flics-amount">{amount}</b>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <div className="flics-detail-left">Initiated on:</div>
                            </Col>
                            <Col md={8}>
                                <div className="flics-detail-right"><b>{new Date().toDateString()}</b></div>
                            </Col>
                        </Row>
                        <Row className="mt-3 mb-2">
                            <Col xs={5} md={5} className="mb-2 mb-md-0 rounded-3 p-2" style={{ border: '1px solid #00FFA3' }}>
                                <div>Signed by <img src={tickCircleIcon} alt="Tick" /></div>
                                <div>you</div>
                            </Col>
                            <Col xs={2} md={2} />
                            <Col xs={5} md={5} className="rounded-3 p-2 border">
                                <div>Signed by <img src={infoIcon} alt="Info" /></div>
                                <div>{nick_name}</div>
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col md={7} style={{ 'padding': '0px', 'padding-top': '5px' }}>
                                <div className="flics-detail-left">
                                    Automatically withdraw after:
                                </div>
                            </Col>
                            <Col md={5} style={{ 'padding': '0px', textAlign: 'left' }}>
                                <div>
                                    <span className="flics-amount">01/ </span>
                                    <span className="flics-detail-right">30Days</span>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="button-wrap" style={{'display': 'flex', 'gap': '15px', 'marginTop': '50px'}}>
                        <CustomButton onClick={async () => { 
                                spinner.setLoadingState(true);
                                if (!authProvider) {
                                    const smartWallet = await initWallet();
                                    authProvider = smartWallet;
                                }

                                const acceptStatus = sendTokensToAcceptFlics(authProvider, flics_address[0]); 
                                spinner.setLoadingState(false);
                                if (acceptStatus) {
                                    setIsCelebrationTriggered(true)
                                }
                            }} >
                            Accept
                        </CustomButton>

                        <CustomButton onClick={async () => { 
                                spinner.setLoadingState(true);
                                if (!authProvider) {
                                    const smartWallet = await initWallet();
                                    authProvider = smartWallet;
                                }

                                const rejectStatus = sendTokensToRejectFlics(authProvider, flics_address[0]); 
                                spinner.setLoadingState(false);
                                if (rejectStatus) {
                                    history("/flics");
                                }
                            }} >
                            Reject
                        </CustomButton>
                    </div> 
                </div>
            </div>
        


            {shouldShowPopup && (
                <Popup
                    message={'Are you sure you want to delete this ROCCA?'}
                    cancelAction={() => setShouldShowPopup(false)}
                >
                    <div className="mb-2"><a>Know your ROCCA {'>'}</a></div>
                </Popup>)
            }

            <ConfettiCelebration 
                    trigger={isCelebrationTriggered}
                    onAnimationComplete={handleAnimationComplete}
                />
        </div>
    );
};

FlicsRequestDetails.prototype = {
    amount: PropTypes.string
}

export default FlicsRequestDetails;