import React, { useState } from "react";
import CustomButton from "../../../components/Button/button";
import TimelineNav from "../../../components/timelinenav/timelineNav";
import photoUploadIcon from "src/assets/images/photouploadplaceholder.svg";
import uploadIcon from "src/assets/images/uploadicon.svg";
import deleteIcon from "src/assets/images/deleteicon.svg";
import { CREATE_CLUB } from "../../../axios/POST_API";
import { USER_VIEW_PROFILE } from "../../../axios/GET_API";
import { useNavigate } from "react-router-dom";
import { useSpinner } from "../../../context/loaderContext/globalSpinnerContext";
import "./createclubphoto.css";

import { sendTokensCreateClub } from "src/web3/CreateClub";
import { useAuth } from 'src/context/web3authProvider/AuthContext';
import useWeb3Auth from "src/web3/useWeb3Auth";

function CreateClubPhoto({
  clubName, 
  description, 
  interests, 
  country, 
  state, 
  city, 
  joiningFee, 
  setSliderPage,
  walletBalance
}) {
  const { authProvider } = useAuth();
  const { initWallet } = useWeb3Auth();
  const spinner = useSpinner();
  const history = useNavigate();
  
  const [profileImage, setProfileImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [uploadError, setUploadError] = useState("");
  
  const allowedImageType = ["image/jpg", "image/jpeg", "image/png"];
  
  const checkImageFile = (file) => {
    if (file.size > 2000000) {
      setUploadError("Image size should be less than 2mb");
      return false;
    }

    if (allowedImageType.indexOf(file.type) < 0) {
      setUploadError("Only JPEG and PNG files are allowed");
      return false;
    }
    setUploadError("");
    return true;
  };

  const selectProfileImage = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }
    const selectedFile = e.target.files[0];
    if (checkImageFile(selectedFile)) {
      setProfileImage(selectedFile);
    }
  };

  const selectCoverImage = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type.startsWith('image/')) {
      if (checkImageFile(selectedFile)) {
        setCoverImage(selectedFile);
      }
    } else {
      setCoverImage(null);
      setUploadError('Please select a valid image file.');
    }
  };
   
  const createClub = async () => {
    if (!coverImage) {
      setUploadError("Please upload cover image");
      return;
    }
    
    if (!profileImage) {
      setUploadError("Please upload profile image");
      return;
    }

    try {
      spinner.setLoadingState(true);
      
      // Fetch user profile did value
      let userDid = localStorage.getItem("did");
      
      // Log each element before making the API call
      console.log('User DID:', userDid);
      console.log('Club Name:', clubName);
      console.log('Description:', description);
      console.log('Profile Image:', profileImage);
      console.log('Cover Image:', coverImage);
      console.log('interests:', interests);
      console.log('Country:', country);
      console.log('State:', state);
      console.log('City:', city);
      console.log('Joining Fee:', joiningFee);

      // Create club API call
      let response = await CREATE_CLUB(
        userDid,
        clubName,
        description,
        profileImage,
        coverImage,
        interests,
        country, 
        state, 
        city,
        joiningFee
      );
      
      // Extract club details
      const clubId = response?.data?.club_info?.club_id;
      
      // Navigate to club page
      history(`/club/${clubId}`);
      
      // Handle Web3 token transfer for club creation
      await handleCreateClubWeb3(authProvider, "50", clubId);
      
    } catch (error) {
      setUploadError(error?.response?.data.message || "Club creation failed");
      console.error(error);
    } finally {
      spinner.setLoadingState(false);
    }
  };

  const handleCreateClubWeb3 = async (authProvider, creationFee, clubId) => {
    try {
      if (!authProvider) {
        const smartWallet = await initWallet();
        authProvider = smartWallet;
      }

      await sendTokensCreateClub(authProvider, creationFee, clubId, walletBalance);
    } catch (error) {
      console.error("Web3 club creation error:", error);
      alert(error.message);
    }
  };

  return (
    <div className="page-page-wrapper">
      <TimelineNav />
      <div className="inner-pages-container">
        <div className="inner-pages-container-wrap">
          <h1 className="page-title">Create a Club</h1>

          <div className="create-club-progress-wrap">
            <div className="active"></div>
            <div className="active"></div>
          </div>

          <div className="create-club-container">
            <div className="upload-club-photo-wrap">
              <div className="upload-profile-image-container">
                <label htmlFor="upload-profile-image">
                  <div 
                    className="upload-profile-image img-btn boxed" 
                    style={{ 
                      backgroundImage: profileImage 
                        ? `url(${URL.createObjectURL(profileImage)})` 
                        : "white",
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                  >
                    <div className="photo-icons-wrap">
                      {!profileImage && (
                        <div style={{position:"relative", top:"15px"}} className="upload-img-icon">
                          <img src={uploadIcon} alt="Upload" />
                        </div>
                      )}
                    </div>

                    <input
                      style={{ display: "none" }}
                      id="upload-profile-image"
                      name="upload-profile-image"
                      type="file"
                      onChange={selectProfileImage}
                      accept="image/jpeg,image/png,image/jpg"
                    />
                  </div>
                </label>

                <span id="club-pro-upload-lbl">Upload Profile Image</span>
              </div>

              <div className="upload-cover-image-container"> 
                <label htmlFor="upload-cover-image">
                  <div>
                    <div 
                      className="upload-cover-image img-btn boxed" 
                      style={{ 
                        backgroundImage: coverImage 
                          ? `url(${URL.createObjectURL(coverImage)})` 
                          : "white",
                        backgroundSize: 'cover',
                        backgroundPosition: 'center'
                      }}
                    >
                      <div className="photo-icons-wrap">
                        {!coverImage ? (
                          <>
                            <div className="placeholder-icon">
                              <img src={photoUploadIcon} alt="Upload" />
                            </div>
                            <div id="club-cover-upload-lbl">
                              <img src={uploadIcon} alt="Upload" />
                              <span>Upload cover image</span>
                            </div>
                          </>
                        ) : (
                          <div className="delete-img-icon icon-btm-right">
                            <img src={deleteIcon} alt="Delete" />
                          </div>
                        )}
                      </div>

                      <input
                        style={{ display: "none" }}
                        id="upload-cover-image"
                        name="upload-cover-image"
                        type="file"
                        onChange={selectCoverImage}
                        accept="image/jpeg,image/png,image/jpg"
                      />
                    </div>

                    {uploadError && <p className="error">{uploadError}</p>}
                  </div>
                </label>
              </div>
            </div>

            <div className="button-wrap">
              <CustomButton onClick={createClub}>Create Club</CustomButton>
            </div>
          </div>
        </div>
      </div>
    </div> 
  );
}

export default CreateClubPhoto;