import React, { useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import TimelineNav from "../../../components/timelinenav/timelineNav";
import FriendsLoader from "../../../components/SkeletonLoader/Friendskeleton/Friendskeleton";
import verifiedIcon from "src/assets/images/verified.svg";
import menukebabIcon from "src/assets/images/menukebab.svg";
import "./style.css";

const { REACT_APP_CDN_HOST } = process.env;

function List({ 
  setSliderPage, 
  friendListData, 
  friendListError, 
  loading, 
  hasMore, 
  onLoadMore 
}) {
  const history = useNavigate();
  const observer = useRef();
  
  // Last element ref callback for infinite scroll
  const lastFriendElementRef = useCallback(node => {
    if (loading) return;
    
    if (observer.current) {
      observer.current.disconnect();
    }
    
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        onLoadMore();
      }
    });
    
    if (node) {
      observer.current.observe(node);
    }
  }, [loading, hasMore, onLoadMore]);

  const changeToFriends = () => setSliderPage("friendlist");
  const changeToReceived = () => setSliderPage("requestreceived");
  const changeToSent = () => setSliderPage("requestsent");

  return (
    <div className="page-page-wrapper">
      {friendListData ? (
        <div>
          <TimelineNav />
          <div className="inner-pages-container">
            <div className="inner-pages-container-wrap">
              <div className="friends-tabs-wrap">
                <button id="cursor" className="active" onClick={changeToFriends}>
                  Friends
                </button>
                <button id="cursor" onClick={changeToReceived}>
                  Requests Received
                </button>
                <button id="cursor" onClick={changeToSent}>
                  Sent
                </button>
              </div>

              <div className="friend-list-container">
                {friendListError && <div className="error">{friendListError}</div>}

                {Array.isArray(friendListData) &&
                  friendListData.map((item, index) => {
                    const isLastElement = index === friendListData.length - 1;
                    let profileImage = "";
                    
                    if (item.profile_pics != null) {
                      profileImage = item.profile_pics[0];
                      profileImage = `${REACT_APP_CDN_HOST}${profileImage}`;
                    }

                    return (
                      <div 
                        className="user-row" 
                        key={index}
                        ref={isLastElement ? lastFriendElementRef : null}
                      >
                        <div className="u-thumb">
                          <img
                            onClick={() => history(`/profile/${item.did}`)}
                            src={profileImage}
                            alt={item.nick_name}
                          />
                        </div>
                        <div className="u-details">
                          <div
                            onClick={() => history(`/profile/${item.did}`)}
                            className="u-name"
                          >
                            {item.nick_name}
                            <span className="verified-icon">
                              <img src={verifiedIcon} alt="verified" />
                            </span>
                          </div>
                        </div>
                        <div className="menu-btn">
                          <button>
                            <img src={menukebabIcon} alt="menu" />
                          </button>
                        </div>

                        <div className="friend-action-menu-container">
                          <p>Friend since 24th January 2021</p>
                          <div className="friend-action-menu-wrap">
                            <div>Share ROCCA with {item.nick_name}</div>
                            <div>Message {item.nick_name}</div>
                            <div>Unfriend {item.nick_name}</div>
                            <div>Block {item.nick_name}</div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                
                {loading && <FriendsLoader />}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <FriendsLoader />
      )}
    </div>
  );
}

export default List;